<script>
import { nanoid } from 'nanoid'
import { reactive, ref, watch, nextTick } from 'vue'
import { directive as clickaway } from 'vue-clickaway'
import { useUI } from '/~/composables/ui'
import BaseMenuItem from './base-menu-item.vue'

export default {
  name: 'base-menu',
  directives: {
    clickaway,
  },
  components: {
    BaseMenuItem,
  },
  props: {
    position: {
      type: String,
      default: 'left',
      validator(value) {
        return /(left|center|right)$/.test(value)
      },
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { lockKeyboardAccessArea, unlockKeyboardAccessArea } = useUI()

    const expanded = ref(false)
    const styles = reactive({})
    const content = ref(null)
    const trigger = ref(null)
    const keyboardAccessAreaId = nanoid()

    watch(expanded, (expanded) => {
      if (expanded) {
        lockKeyboardAccessArea({
          id: keyboardAccessAreaId,
          rootElement: content.value,
          previousActiveElement: trigger.value?.querySelector(
            'button:not(:disabled)'
          ),
        })
      } else {
        unlockKeyboardAccessArea(keyboardAccessAreaId)
      }
    })

    return {
      expanded,
      styles,
      content,
      trigger,
    }
  },
  methods: {
    show() {
      this.expanded = true
      this.setPosition()

      nextTick(() => {
        const index = this.items.findIndex(
          (item) => !item.hidden && !item.disabled
        )

        if (index > -1) {
          this.$refs.menuItem[index].$el.focus()
        }
      })
    },
    hide() {
      this.expanded = false
    },
    toggle() {
      if (this.expanded) {
        this.hide()
      } else {
        this.show()
      }
    },
    setPosition() {
      const horizontal = this.setPositionHorizontal()
      const vertical = this.setPositionVertical()

      this.styles = {
        ...horizontal,
        ...vertical,
      }
    },
    setPositionHorizontal() {
      const styles = {}
      const triggerWidth = this.$refs.trigger.clientWidth
      const contentWidth = this.$refs.content.clientWidth

      if (/center/.test(this.position)) {
        styles.left = `${-(contentWidth / 2) + triggerWidth / 2}px`
      } else if (/left/.test(this.position)) {
        styles.left = 0
      } else if (/right/.test(this.position)) {
        styles.right = 0
      }

      return styles
    },
    setPositionVertical() {
      const styles = {}
      const innerHeight = window.innerHeight
      const { top } = this.$refs.trigger.getBoundingClientRect()
      const { height } = this.$refs.content.getBoundingClientRect()
      const bottomOffset = 100 // mobile and desktop footer with some padding

      const headerElem = document.querySelector('.app-header')
      let headerHeight = 0

      if (headerElem) {
        const headerRect = headerElem.getBoundingClientRect()

        headerHeight = headerRect.height - Math.abs(headerRect.top)
      }
      const topOffset = headerHeight > 0 ? headerHeight : 0

      if (
        top + height + bottomOffset > innerHeight &&
        top > height + topOffset &&
        innerHeight >= 540
      ) {
        styles.bottom = '100%'
      } else {
        styles.top = '100%'
      }

      return styles
    },
    onItemClick(item) {
      this.hide()

      if (item.click instanceof Function) {
        item.click()
      }
    },
  },
}
</script>

<template>
  <div
    :class="{
      'z-20': expanded,
    }"
    @keyup.esc.stop="hide"
  >
    <div v-clickaway="hide" class="relative">
      <div
        ref="trigger"
        class="relative z-1 cursor-pointer"
        @click.stop="toggle"
      >
        <slot name="trigger" />
      </div>
      <div
        v-show="expanded"
        ref="content"
        :style="styles"
        class="absolute z-40 mx-1 rounded-md border bg-default shadow"
        data-testid="base-menu-content"
        @click="hide"
      >
        <div v-if="items" class="m-0 list-none p-0 text-sm">
          <base-menu-item
            v-for="(item, idx) in items"
            ref="menuItem"
            :key="`item${idx}`"
            :disabled="item.disabled"
            :hidden="item.hidden"
            :icon="item.icon"
            @click="onItemClick(item)"
          >
            {{ item.content }}
          </base-menu-item>
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>
